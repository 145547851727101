.chevronDown {
    margin-top: 3px;
    width: 16px;
    height: 16px;
    display: flex;
    user-select: none;
    background-image: url(../../../icons/chevron-down.svg);
}

.paragraph {
    background-image: url(../../../icons/text-paragraph.svg);
}

.largeHeading, .h1 {
    background-image: url(../../../icons/type-h1.svg);
}

.smallHeading, .h2 {
    background-image: url(../../../icons/type-h2.svg);
}

.bulletList, .ul {
    background-image: url(../../../icons/list-ul.svg);
}

.numberedList, .ol {
    background-image: url(../../../icons/list-ol.svg);
}

.quote {
    background-image: url(../../../icons/chat-square-quote.svg);
}

.blockType {
    background-size: contain;
    display: block;
    width: 18px;
    height: 18px;
    margin: 2px;
  }

  .dropdown {
    z-index: 5;
    display: block;
    position: absolute;
    bottom: -25px;
    left: 90px;
    box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1),
        inset 0 0 0 1px rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    min-width: 70px;
    min-height: 40px;
    background-color: #fff;
  }

  .blockControls button:hover {
    background-color: #efefef;
  }
  
  .blockControls button:focus-visible {
    border-color: blue;
  }

  .dropdown .item {
    margin: 0 8px 0 8px;
    padding: 8px;
    color: #050505;
    cursor: pointer;
    line-height: 16px;
    font-size: 15px;
    display: flex;
    align-content: center;
    flex-direction: row;
    flex-shrink: 0;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 8px;
    border: 0;
    min-width: 150px;
  }
  
  .dropdown .item .active {
    display: flex;
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  
  .dropdown .item:first-child {
    margin-top: 8px;
  }
  
  .dropdown .item:last-child {
    margin-bottom: 8px;
  }
  
  .dropdown .item:hover {
    background-color: #eee;
  }
  
  .textItem {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
    flex-grow: 1;
  }
  
  .dropdownIcon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 12px;
    line-height: 16px;
    background-size: contain;
  }
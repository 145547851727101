.undo {
    background-image: url(../../icons/arrow-counterclockwise.svg);
}

.redo {
    background-image: url(../../icons/arrow-clockwise.svg);
}

.bold {
    background-image: url(../../icons/type-bold.svg);
}

.italic {
    background-image: url(../../icons/type-italic.svg);
}

.underline {
    background-image: url(../../icons/type-underline.svg);
}

.strikethrough {
    background-image: url(../../icons/type-strikethrough.svg);
}

.code {
    background-image: url(../../icons/code.svg);
}

.link {
    background-image: url(../../icons/link.svg);
}

.leftAlign {
    background-image: url(../../icons/text-left.svg);
}

.centerAlign {
    background-image: url(../../icons/text-center.svg);
}

.rightAlign {
    background-image: url(../../icons/text-right.svg);
}

.justifyAlign {
    background-image: url(../../icons/justify.svg);
}


.toolbarItem {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 8px;
    cursor: pointer;
    vertical-align: middle;
}

.toolbarItem:disabled {
    cursor: not-allowed;
}

.toolbarItem.spaced {
    margin-right: 2px;
}

.toolbarItem i.format {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.toolbarItem:disabled i.format {
    opacity: 0.2;
}

.toolbarItem.active {
    background-color: rgba(223, 232, 250, 0.3);
}

.toolbarItem.active i {
    opacity: 1;
}

.toolbarItem:hover:not([disabled]) {
    background-color: #eee;
}

.dividerHorizontal {
    height: 1px;
    width: auto;
    background-color: #eee;
    margin: 4px 0px;
}

.dividerVertical {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
}

.toolbar {
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    margin-bottom: 1px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
}